<template>
  <div>
    <ManageServiceSystemStudent />
  </div>
</template>
<script>
import ManageServiceSystemStudent from "@/components/ManageServiceSystemStudent/ManageServiceSystemStudent";
export default {
  components: {
    ManageServiceSystemStudent,
  },
  created() {},
};
</script>
